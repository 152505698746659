@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Saira:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* apply styling to all instead of defining separately where possible */
@layer base {
  * {
    @apply p-0;
    @apply m-0;
    @apply box-border;
  }

  body {
    @apply font-[Saira];
    @apply bg-[#0a192f];
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

/* Specific stylings below as needed or overriding Tailwind CSS*/

/* Body */
/* Hide scrollbar */
body::-webkit-scrollbar {
  display: none;
}

/* Project Component */
.project-icon-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 200px; */
}

/* Skills Component */
/* React Logo */
/* Overriding tailwind css animation spin time to slow down rotation */
.animate-spin {
  animation: spin 3500ms linear infinite;
}

/* Footer Component */
/* Instagram Icon */
/* Had to do fallbacks due radial gradient not showing on hover on iOS devices */
.instagram:hover {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -moz-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
